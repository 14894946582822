@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;500&display=swap');

/* body,
main {
  background: #f9fafa;
}
*/

.backdrop-ellipsis {
  width: 75%;
  height: 800px;
  top: 5%;
  right: 0;
  position: absolute;
  background: #cb501b;
  opacity: 0.15;
  /* filter: blur(552.457px); - breaks safari */
  z-index: -1;
}

.screen-window {
  background: rgba(255, 255, 255, 0.3);
  border: 1px solid rgba(255, 255, 255, 0.28);
  box-sizing: border-box;
  backdrop-filter: blur(112px);
}

.inner-screen {
  border-radius: 10px;
  box-shadow: 0px 60px 60px rgba(0, 37, 109, 0.07);
}

#terms h1,
#terms h2,
#terms h3 {
  margin-top: 20px;
  margin-bottom: 12px;
}

#terms h1 {
  font-size: 24px;
  text-align: center;
  font-weight: bold;
}

#terms h2 {
  font-size: 20px;
  font-weight: bold;
}

#terms h3 {
  font-size: 16px;
  font-weight: bold;
}

#terms h4 {
  margin-top: 12px;
  margin-bottom: 8px;
  font-weight: bold;
}

#terms h5 {
  margin-top: 8px;
  font-size: 14px;
  font-weight: 500;
}

.cookie-banner {
  position: fixed !important;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7) !important;
}

.cookie-banner .button-close {
  position: static !important;
  margin-left: 15px;
  background-color: rgba(203, 80, 27, 1) !important;
  color: white !important;
  font-size: 14px !important;
}

.minimal-footer > div > * {
  margin-bottom: 1.2rem;
}

@media screen and (max-width: 640px) {
  .balance-block {
    display: grid;
    grid-template-rows: repeat(4, minmax(0, 1fr));
    align-items: flex-end;
  }
}
